require('./bootstrap');

const HamburgerBtn = document.querySelector(".hamburger-icon");
const SearchIcon = document.querySelectorAll(".search-icon");
const SearchIconImg = document.querySelector(".search-icon-img");
const SearchIconImgDesktop = document.querySelector(".search-icon-img-desktop");
const SearchInput = document.querySelector(".search-input");
const SearchInputMobile = document.querySelector(".search-input-mobile");
const SearchInputContainer = document.querySelector(".search-input-container");
const Overlay = document.querySelector(".overlay");
const HeaderNavItem = document.querySelector(".header-nav-item");
const parent = document.querySelector(".common-header");
//for overlay and position:fixed navbar
const MainContent = document.querySelector(".main-content");
const navItemContainer = document.querySelector(".nav-item-container");

//for categoty filter
const categoryOptionBtn = document.querySelector("#category-option-btn");
const categoryOptionContainer = document.querySelector("#category-item-container");
const tagOptionBtn = document.querySelector("#tag-option-btn");
const tagOptionContainer = document.querySelector("#tag-item-container");
const optionArrow = document.querySelector(".option-arrow");
const footerMobileMenu = document.querySelector(".mobile-menu-footer");
const FilterDropdownOverlay = document.querySelector(".filter-dropdown--overlay");
const FilterDropdownOverlayCategory = document.querySelector(".filter-dropdown--overlay__category");
const PageType = document.querySelector(".page-type");
const HomePageBgGraphic = document.querySelector(".home-page-bg-graphics");

//article page link 

const ArticlePageLinkBtn = document.querySelectorAll(".article-page-link-btn");
const ProjectPageLinkBtn = document.querySelectorAll(".project-page-link-btn");



const arrowClose = `<svg width="13" height="8" viewBox="0 0 13 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">         
                <path
                    d="M11.7946 0.773952C11.4053 0.384679 10.7743 0.384335 10.3846 0.773183L6.5 4.64934L2.61538 0.773183C2.22569 0.384335 1.59466 0.384679 1.20538 0.773952C0.815811 1.16352 0.815811 1.79515 1.20538 2.18472L5.79289 6.77223C6.18342 7.16275 6.81658 7.16275 7.20711 6.77223L11.7946 2.18472C12.1842 1.79515 12.1842 1.16352 11.7946 0.773952Z"
                    fill="#318181" />
            </svg>`;

const arrowOpen = `<svg width="13" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.70711 1.00203C6.31658 0.611504 5.68342 0.611504 5.29289 1.00203L0.705384 5.58954C0.315811 5.97911 0.315811 6.61073 0.705384 7.00031C1.09466 7.38958 1.72569 7.38992 2.11538 7.00107L6 3.12492L9.88462 7.00108C10.2743 7.38992 10.9053 7.38958 11.2946 7.00031C11.6842 6.61073 11.6842 5.97911 11.2946 5.58954L6.70711 1.00203Z" fill="#318181"/>
</svg>`;

const searchImageSvg = `<svg class= "searchIconSvg" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.125" cy="19.5" r="18.5476" stroke="#9EF8EE" stroke-width="0.904762"/>
        <path d="M21.9868 21.921L25.9108 25.8333M23.6489 17.9167C23.6489 21.0397 21.1172 23.5714 17.9941 23.5714C14.8711 23.5714 12.3394 21.0397 12.3394 17.9167C12.3394 14.7936 14.8711 12.2619 17.9941 12.2619C21.1172 12.2619 23.6489 14.7936 23.6489 17.9167Z" stroke="#9EF8EE" stroke-width="1.80952" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;

const closeIconSvg = `<svg width="39" height="39" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.625" cy="21.5" r="20.5" fill="#9EF8EE" stroke="#9EF8EE"/>
        <g clip-path="url(#clip0_1296_976)">
        <rect x="14.125" y="14" width="15" height="15" fill="#9EF8EE"/>
        <line x1="16.1413" y1="15.7551" x2="27.455" y2="27.0688" stroke="#1C4848" stroke-width="2" stroke-linecap="round"/>
        <line x1="15.7271" y1="27.0687" x2="27.0408" y2="15.755" stroke="#1C4848" stroke-width="2" stroke-linecap="round"/>
        </g>
        <rect x="14.625" y="14.5" width="14" height="14" stroke="#9EF8EE"/>
        <defs>
        <clipPath id="clip0_1296_976">
        <rect x="14.125" y="14" width="15" height="15" fill="white"/>
        </clipPath>
        </defs>
        </svg>
`;

const NavbarCloseIcon = `<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.2222" cy="19.5" r="18.5" stroke="#9EF8EE"/>
<g clip-path="url(#clip0_125_1908)">
<line x1="14.2387" y1="13.7551" x2="25.5524" y2="25.0688" stroke="#9EF8EE" stroke-width="2" stroke-linecap="round"/>
<line x1="13.8245" y1="25.0688" x2="25.1382" y2="13.7551" stroke="#9EF8EE" stroke-width="2" stroke-linecap="round"/>
</g>
<defs>
<clipPath id="clip0_125_1908">
<rect width="15" height="15" fill="white" transform="translate(12.2224 12)"/>
</clipPath>
</defs>
</svg>`

const HamburgerIcon = `<svg width="38" height="39" viewBox="0 0 38 39" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="19" cy="19.5" r="18.5" stroke="#9EF8EE" />
                                    <line x1="10.9524" y1="13.9762" x2="27.0476" y2="13.9762"
                                        stroke="#9EF8EE" stroke-width="2" stroke-linecap="round" />
                                    <line x1="10.9526" y1="19.4048" x2="27.0479" y2="19.4048"
                                        stroke="#9EF8EE" stroke-width="2" stroke-linecap="round" />
                                    <line x1="10.9526" y1="24.8333" x2="27.0479" y2="24.8333"
                                        stroke="#9EF8EE" stroke-width="2" stroke-linecap="round" />
                                </svg>`

HamburgerBtn.addEventListener("click", () => {

    //search icon controls
    SearchInputContainer.classList.add("d-none");
    //SearchInputContainer.classList.add("d-lg-none");



    SearchIconImg.innerHTML = searchImageSvg;
    SearchIconImgDesktop.innerHTML = searchImageSvg;




    if (HeaderNavItem.classList.contains("d-none")) {
        HeaderNavItem.classList.add("d-flex");
        HeaderNavItem.classList.remove("d-none");
        HamburgerBtn.innerHTML = NavbarCloseIcon;
        //overlay control
        Overlay.classList.remove("d-none");
    
        //mobile menu footer visibility control
        footerMobileMenu.classList.remove("d-none");

        //nav-bg-graphic control
        if (!navItemContainer.classList.contains("nav-bg-graphic")) {
            navItemContainer.classList.add("nav-bg-graphic")
        }

    } else {
        HeaderNavItem.classList.remove("d-flex");
        HeaderNavItem.classList.add("d-lg-flex");
        HeaderNavItem.classList.add("d-none");
        HamburgerBtn.innerHTML = HamburgerIcon;
        //overlay control
        Overlay.classList.add("d-none");

        //mobile menu footer visibility control
        footerMobileMenu.classList.add("d-none");

        if (navItemContainer.classList.contains("nav-bg-graphic")) {
            navItemContainer.classList.remove("nav-bg-graphic")
        }

    };

 
});


//overlay onclick listener 
Overlay.addEventListener("click", () => {
    Overlay.classList.add("d-none");
    HeaderNavItem.classList.add("d-none");
    SearchInputContainer.classList.add("d-none");
    HamburgerBtn.innerHTML = HamburgerIcon;
    SearchIconImg.innerHTML = searchImageSvg;
    SearchIconImgDesktop.innerHTML = searchImageSvg;
    HeaderNavItem.classList.add("d-lg-flex");

    //mobile menu footer visibility control
    footerMobileMenu.classList.add("d-none");

    addPadding();

    //nav-bg-graphics controls
    if (navItemContainer.classList.contains("nav-bg-graphic")) {
        navItemContainer.classList.remove("nav-bg-graphic")
    }

});



// array of search icon divs
const SearchIcons = [...SearchIcon]

SearchIcons.forEach(element => {

    element.addEventListener("click", () => {

        //search input container display toggle
        SearchInputContainer.classList.toggle("d-none");

        //hamburger controls
        HeaderNavItem.classList.add("d-none");
        HeaderNavItem.classList.remove("d-flex");
        // HeaderNavItem.classList.toggle("d-lg-flex");
        HamburgerBtn.innerHTML = HamburgerIcon;

        //mobile menu footer visibility control
        footerMobileMenu.classList.add("d-none");

        //nav-bg-graphics controls
        if (navItemContainer.classList.contains("nav-bg-graphic")) {
            navItemContainer.classList.remove("nav-bg-graphic")
        }


        if (SearchInputContainer.classList.contains("d-none")) {
            SearchIconImg.innerHTML = searchImageSvg;
            SearchIconImgDesktop.innerHTML = searchImageSvg;
            HeaderNavItem.classList.add("d-lg-flex");
            //overlay control
            Overlay.classList.add("d-none");

            
        }
        else {
            SearchIconImg.innerHTML = closeIconSvg;
            SearchIconImgDesktop.innerHTML = closeIconSvg;
            HeaderNavItem.classList.remove("d-lg-flex");
            //overlay control
            Overlay.classList.remove("d-none");
        }

        SearchInput.focus();
        SearchInputMobile.focus();
    })
});

//css update 

window.addEventListener("resize",()=>{
    addPadding();
    if(MainContent.clientWidth > 970){
        if (navItemContainer.classList.contains("nav-bg-graphic")) {
            navItemContainer.classList.remove("nav-bg-graphic")
        }
    }else{
        if(!(HeaderNavItem.classList.contains("d-none"))){
            if (!navItemContainer.classList.contains("nav-bg-graphic")) {
                navItemContainer.classList.add("nav-bg-graphic")
            }
        }
    }

});

function addPadding() {
    // MainContent.style.paddingTop = `${navItemContainer.clientHeight}px`;
}


function initialize() {
    addPadding();
    if(parent){
        // coverting p tag to h1 tag
        let children = parent.children[0];
        let h1 = document.createElement("h1");
        h1.innerHTML = children.innerHTML;
        parent.firstElementChild.remove();
        parent.appendChild(h1);
    }
}


initialize();


//custom dropdown for projects
if (categoryOptionBtn){

    categoryOptionBtn.addEventListener("click", () => {
        if (categoryOptionContainer.classList.contains("d-none")) {
            optionArrow.innerHTML = arrowOpen;
            categoryOptionContainer.classList.remove("d-none");
            FilterDropdownOverlayCategory.classList.remove("d-none");
            
        } else {
            optionArrow.innerHTML = arrowClose;
            categoryOptionContainer.classList.add("d-none");
            FilterDropdownOverlayCategory.classList.add("d-none");
        }
    });
    
}
if (FilterDropdownOverlayCategory){

    FilterDropdownOverlayCategory.addEventListener("click",()=>{
        categoryOptionContainer.classList.add("d-none");
        FilterDropdownOverlay.classList.add("d-none");
        
    })   
}

if (tagOptionBtn){

    //custom dropdown for projects
    tagOptionBtn.addEventListener("click", () => {
    if (tagOptionContainer.classList.contains("d-none")) {
        optionArrow.innerHTML = arrowOpen;
        tagOptionContainer.classList.remove("d-none");
        FilterDropdownOverlay.classList.remove("d-none");

        
    } else {
        optionArrow.innerHTML = arrowClose;
        tagOptionContainer.classList.add("d-none");
        FilterDropdownOverlay.classList.add("d-none");

    }
});

}

if (FilterDropdownOverlay){

    FilterDropdownOverlay.addEventListener("click", () => {
        tagOptionContainer.classList.add("d-none");
        FilterDropdownOverlay.classList.add("d-none");
        
    })
}

//adding background graphics on the basis of page type
if(PageType){
    if (PageType.innerHTML == "Gateway"){
        MainContent.classList.add("bg-circle");
        MainContent.classList.remove("bg-arc");


    } else if(PageType.innerHTML == "Content"){
        MainContent.classList.add("bg-arc");
        MainContent.classList.remove("bg-circle");


    } else{
        MainContent.classList.remove("bg-arc");
        MainContent.classList.remove("bg-circle");
    }
}

if (HomePageBgGraphic){
    if (HomePageBgGraphic.innerHTML == "/") {
        MainContent.classList.remove("bg-arc");
        MainContent.classList.add("bg-circle");
    }
}


//article and project page "copy link on click"

if (ArticlePageLinkBtn) {
    ArticlePageLinkBtn.forEach(element => {

        const ArticlePageLink = document.querySelector(".article-page-link");
        element.addEventListener("click", () => {
            // Copy the text from the text field
            navigator.clipboard.writeText(ArticlePageLink.innerHTML).then(
                ()=>{
                    // console.log("success");
                },
                ()=>{
                    // console.log("failed");
                }
            );

        })
    })


}

if (ProjectPageLinkBtn) {
    ProjectPageLinkBtn.forEach(element => {

        const ProjectPageLink = document.querySelector(".project-page-link");
        element.addEventListener("click", () => {
            // Copy the text from the text field
            navigator.clipboard.writeText(ProjectPageLink.innerHTML).then(
                () => {
                    // console.log("success");
                },
                () => {
                    // console.log("failed");
                }
            );

        })
    })
}